const MONDIAL_RELAY = 'Mondial Relay';
window.discount_voucher_code = {};

function set_error(message) {
  var error_field = document.querySelector("div.cart-alert-displayed");
  error_field.style.display = "block";
  error_field.textContent = message;
  setTimeout(function(){
    error_field.style.display = "none";
    error_field.textContent = "";
  }, 3000);
}

function init_prevent_submit() {
  $('#new_order').submit(function() {
    var bool_to_ret = false;
    var input_checked = document.querySelector(".shipping-method:checked");
    const shipping_name = (input_checked) ? input_checked.parentNode.parentNode.parentNode.querySelector(".shipping-name").textContent : null;
    const mondialrelay_office = document.querySelector("#selected-relay-point");
    const cgv_checkbox = document.querySelector("#cgv-validation:checked");
    var mondialrelay_infos = document.querySelector("#address-relay-point");
    const infos_pr = document.querySelector("#infos-pr");

    if (!cgv_checkbox)
      set_error("Veuillez accepter les Conditions Générales de Vente")
    else if (!input_checked)
      set_error("Veuillez remplir le champ Méthode de Livraison");
    else if (shipping_name && shipping_name == MONDIAL_RELAY && !mondialrelay_office.value)
      set_error("Veuillez choisir un Point Relais® Mondial Relay");
    else
      bool_to_ret = true;
    mondialrelay_infos.value = infos_pr.textContent
    //console.log("INFOS: ", infos_pr.textContent);
    return bool_to_ret;
  });
}

function init_mondial_relay() {
  $("#mondialrelay-widget").MR_ParcelShopPicker({
    Target: "#selected-relay-point",
    Brand: "CC223PRM",
    //Brand: "BDTEST  ",
    Country: "FR",
    PostCode: "75000",
    // Mode de livraison (Standard [24R], XL [24L], XXL [24X], Drive [DRI])
    ColLivMod: "24R",
    NbResults: "10",
    ShowResultsOnMap: true,
    DisplayMapInfo: true,
    // Liste des pays selectionnable par l'utilisateur pour la recherche: codes ISO 2 lettres
    AllowedCountries: "FR",
    // Activer la recherche de la position lorsque le navigateur de l'utilisateur le supporte?
    EnableGeolocalisatedSearch: "true",
    TargetDisplayInfoPR: "#infos-pr",
    });
}

function init_event_button() {
  $(".cart-remove-item").on("click", function(e) {
      const cart_item_id = this.parentNode.querySelector("input").value;
      $.ajax({
        method: "DELETE",
        url: "/cart_items/"+cart_item_id.toString(),
        contentType: "application/json",
        dataType: "json"
      })
      .fail(function(msg) {
        // console.log("[ERROR]{Header / remove_cart_item}:")
        // console.log(msg)
      })
      .done(function( data ) {
        // console.log("[DONE]{Header / remove_cart_item}:")
        // console.log(data)
        location.reload();
      });
  });

  $("input.shipping-method").on("change", function(e) {
    const shipping_name = this.parentNode.parentNode.parentNode.querySelector(".shipping-name").textContent;
    var mondialrelay_layout = document.querySelector("#mondialrelay-widget");
    if (shipping_name == MONDIAL_RELAY) {
      mondialrelay_layout.style.display = "block";
    } else {
      mondialrelay_layout.style.display = "none";
    }
  });
}

function fade(element) {
    var op = 1;  // initial opacity
    var timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);
}

function unfade(element) {
    var op = 0.1;  // initial opacity
    element.style.display = 'block';
    var timer = setInterval(function () {
        if (op >= 1){
            clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1;
    }, 10);
}

function handle_format_voucher_shipping() {
  console.log("HANDLE FREE SHIPPING");
  document.querySelector("#discount-voucher-section span").textContent = "FRAIS DE LIVRAISON OFFERT";
  fade(document.querySelector(".shipping-section"))
}

function reset_interface() {
  document.querySelector("#discount-voucher-section span").textContent = "";
  let shipping_section = document.querySelector(".shipping-section")
  if (shipping_section.style.display == "none")
    unfade(shipping_section)
}

function init_discount_voucher_listener() {
  let timer;
  const waitTime = 1000;

  const search_voucher = (text) => {
      $.ajax({
        method: "GET",
        url: "/discount_vouchers/exists",
        contentType: "application/json",
        data: {discount_voucher_code: text},
        dataType: "json"
      })
      .fail(function(msg) {
        console.log("[ERROR]{Cart / discount_vouchers_exists}:")
        console.log(msg)
      })
      .done(function( data ) {
        console.log("[DONE]{Cart / discount_vouchers_exists}:")
        console.log(data)
        if (Object.keys(data.data).length > 0) {
          window.discount_voucher_code = data.data;
          if (window.discount_voucher_code.free_shipping_fees == true) {
            handle_format_voucher_shipping();
          }
        } else
          reset_interface();
      });
  };

  const input = document.querySelector('#discount-voucher-field');
  input.addEventListener('keyup', (e) => {
      const text = e.currentTarget.value;
      clearTimeout(timer);
      timer = setTimeout(() => {
          search_voucher(text);
      }, waitTime);
  });

}

$(document).on('turbolinks:load', function() {
  if (!(document.page.controller() === 'carts' && document.page.action() === 'show'))
    return;

  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');

  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  init_event_button();
  init_mondial_relay();
  init_prevent_submit();
  init_discount_voucher_listener();

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of HSScrollBar component
  //$.HSCore.components.HSScrollBar.init($('.js-scrollbar'));

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // initialization of form validation
  //$.HSCore.components.HSValidation.init('.js-validate');

  // initialization of custom select
  //$.HSCore.components.HSSelect.init('.js-custom-select');

  // initialization of quantity counter
  $.HSCore.components.HSCountQty.init('.js-quantity');

  // initialization of step form
  $.HSCore.components.HSStepForm.init('.js-step-form');
});

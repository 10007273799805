module.exports = {
  init_add_to_cart: function () {
    $(".add-to-cart").on("click", function(e) {
      const product_id = this.parentElement.querySelector('input').value;
      $.ajax({
        method: "POST",
        url: "/cart_items?product_id="+product_id.toString(),

        contentType: "application/json",
        dataType: "json"
      })
      .fail(function(msg) {
        console.log("[ERROR]{Common / add_to_cart}:")
        console.log(msg)
      })
      .done(function( data ) {
        console.log("[DONE]{Common / add_to_cart}:")
        console.log()
        location.reload();
      });
    });
  }
};

function init_fade_out_cart() {
  const global_msg = document.querySelector(".alert.global-message");
  if (global_msg && global_msg.style.display != "none") {
    $('.alert.global-message').delay(3000).fadeOut(800);
  }
}

function display_success_msg() {
  document.querySelector(".alert-msg.alert-success.js-product-alert").style.display = "initial";
  setTimeout(function(){ document.querySelector(".alert-msg.alert-success.js-product-alert").style.display = "none"; }, 3000);
}

function display_error_msg() {
  document.querySelector(".alert-msg.alert-error.js-product-alert").style.display = "initial";
  setTimeout(function(){ document.querySelector(".alert-msg.alert-error.js-product-alert").style.display = "none"; }, 3000);
}

function remove_current_cart() {
  var cart_id = document.querySelector(".cart-id").value;
  var cart_uuid = document.querySelector(".cart-uuid").value;

  $.ajax({
    method: "DELETE",
    url: "/carts/"+cart_id.toString()+"?uuid="+cart_uuid,
    contentType: "application/json",
    dataType: "json"
  })
  .fail(function(msg) {
    console.log("[ERROR]{Header / remove_cart}:")
    console.log(msg)
  })
  .done(function( data ) {
    console.log("[DONE]{Header / remove_cart}:")
    console.log(data)
    if (data.state != "error")
      location.reload();
  });
}

// function init_add_to_cart() {
//   console.log("Add to cart");
//   $('.add-to-cart').unbind('click');
//   $('.add-to-cart').on('click', function(e) {
//     const product_id = this.parentElement.querySelector('input').value;
//     $.ajax({
//       method: "POST",
//       url: "/cart_items?product_id="+product_id.toString(),
//       contentType: "application/json",
//       dataType: "json"
//     })
//     .fail(function(msg) {
//       console.log("[ERROR]{Common / add_to_cart}:")
//       console.log(msg)
//     })
//     .done(function( data ) {
//       console.log("[DONE]{Common / add_to_cart}:")
//       console.log()
//       location.reload();
//     });
//   });
// }

function init_remove_item_header() {
  $(".remove-cart-item").on("click", function(e) {
      const cart_item_id = this.value;
      $.ajax({
        method: "DELETE",
        url: "/cart_items/"+cart_item_id.toString(),
        contentType: "application/json",
        dataType: "json"
      })
      .fail(function(msg) {
        console.log("[ERROR]{Header / remove_cart_item}:")
        console.log(msg)
      })
      .done(function( data ) {
        console.log("[DONE]{Header / remove_cart_item}:")
        console.log(data)
        location.reload();
        // var cart_item_bloc = document.querySelector(".cart-item-"+cart_item_id.toString())
        // if (cart_item_bloc) {
        //   cart_item_bloc.remove();
        // }
      });
  });
}

function getTimezoneOffset() {
  function z(n){return (n<10? '0' : '') + n}
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0? '+' : '-';
  offset = Math.abs(offset);
  return sign + z(offset/60 | 0) + z(offset%60);
}

function init_countdown_header() {
  var cart_timer = document.querySelector('.cart-updated_at');
  if (cart_timer) {
    // console.log("CART TIMER:")
    // console.log(cart_timer.value)
    var dateString = cart_timer.value.toString();
    var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
    var dateArray = reggie.exec(dateString);
    var dateObject = new Date(Date.UTC(
        (+dateArray[1]),
        (+dateArray[2])-1, // Careful, month starts at 0!
        (+dateArray[3]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[6])
    ));
    console.log(dateObject);
    var countDownDate = dateObject.getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      document.querySelector("#countdown-cart span").innerHTML = minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        remove_current_cart();
        document.querySelector("#countdown-cart span").innerHTML = "EXPIRED";
      }
    }, 1000);
  }
}

function init_click_toggle() {
  $("a[data-toggle='collapse']:not([class*='w-text'])").on("click", function(e) {
    if (e.target.textContent.includes("plus")) {
      e.target.innerHTML = "Voir moins"
    } else {
      e.target.innerHTML = "Voir plus"
    }
  })
}

function init_header() {
  init_remove_item_header();
  init_countdown_header();
  init_click_toggle();
}

$(document).on('turbolinks:load', function() {
  init_header();
  init_fade_out_cart();
  //init_add_to_cart();

  $("#acceptCookie").on("click", function(e) {
    var http = new XMLHttpRequest();
    var url = '/accept_cookie';
    http.open('GET', url, true);
    http.onreadystatechange = function() {
        if(http.readyState == 4 && http.status >= 200 || http.status < 300) {
            $(".cookieConsent").css("display", "none");
        }
    }
    http.send();
    // $.ajax({
    //   url: "/accept_cookie",
    //   method: "POST"
    // })
    // .success(function(result){
    //   $(".cookieConsent").css("display", "none");
    // });
  });
});

var global_function = require("./custom.js")

$(document).on('turbolinks:load', function() {
  global_function.init_add_to_cart();
  if (!(document.page.controller() === 'products' && document.page.action() === 'show'))
    return;

  // initialization of carousel
  $.HSCore.components.HSCarousel.init('.js-carousel');

  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');

  // initialization of HSMegaMenu plugin
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // initialization of HSScrollBar component
  //$.HSCore.components.HSScrollBar.init($('.js-scrollbar'));

  // initialization of quantity counter
  $.HSCore.components.HSCountQty.init('.js-quantity');

  // initialization of tabs
  //$.HSCore.components.HSTabs.init('[role="tablist"]');

  // $(window).on('resize', function () {
  //   setTimeout(function () {
  //     $.HSCore.components.HSTabs.init('[role="tablist"]');
  //   }, 200);
  // });
  //global_function.init_add_to_cart();
});


$(document).on('turbolinks:load', function() {
  if (!(document.page.controller() === 'products' && document.page.action() === 'index'))
    return;
    //global_function.init_add_to_cart();
});

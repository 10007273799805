$(document).on('turbolinks:load', function() { 
  if (!(document.page.controller() === 'contacts' && document.page.action() === 'new'))
    return;

  function set_form_error_contact(message) {
    var error_field = $("div.alert.alert-danger.error-form-contact-new");
    error_field.css("display", "block");
    error_field.text(message);
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');

  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of HSScrollBar component
  //  $.HSCore.components.HSScrollBar.init($('.js-scrollbar'));

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');



  var name = $("input[name='contact[name]']");
  var email = $("input[name='contact[email]']");
  var subject = $("input[name='contact[subject]']");
  var phone = $("input[name='contact[phone]']");
  var message = $("textarea[name='contact[message]']");
  //var terms_of_service = $("input[name='store_recommendation[terms_of_service]'][type='checkbox']");

  var phone_regex = new RegExp(/^0[6-7]([\.|\-|\s]*[0-9]{2}){4}$/);

  $('#new_contact').submit(function() {
    var bool_to_ret = false;
    if (name.val() == undefined || name.val() == "")
      set_form_error_contact("Le champ 'Nom / Prénom' est vide");
    else if (email.val() == undefined || email.val() == "")
      set_form_error_contact("Le champ 'Email' est vide");
    else if (subject.val() == undefined || subject.val() == "")
      set_form_error_contact("Le champ 'Objet' est vide");
    // else if (phone.val() == undefined || phone.val() == "")
    //   set_form_error_contact("Le champ 'Téléphone' est vide");
    // else if (terms_of_service[0].checked == false)
    //   set_form_error_contact("Le champ 'CGU' n'a pas été accepté");
    else if (message.val() == undefined || message.val() == "")
      set_form_error_contact("Le champ 'Message' est vide");
    else if (!validateEmail(email.val()))
      set_form_error_contact("Le format d'email n'est pas valide");
    else
      bool_to_ret = true;
    if (bool_to_ret == true)
      $(".loading-contact-new").css("display", "block");
    return bool_to_ret;
  });

});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("./vendors/jquery.mondialrelay")
// require jquery.plugin.mondialrelay
//require("jquery.mousewheel")

//popper.min.js
require("./vendors/jquery.countdown.min.js")
require("./vendors/slick.js")
require("./vendors/jquery.cubeportfolio.js")
// require("./vendors/widget.js")
// require("./vendors/menu.js")
// require("./vendors/mouse.js")
//require("./vendors/slider.js")
require("./vendors/hs.megamenu.js")
//require("./vendors/jquery.mCustomScrollbar.concat.min.js")
require("./vendors/appear.js")
//require("./vendors/jquery.themepunch.tools.min.js")
//require("./vendors/jquery.themepunch.revolution.min.js")
// require("./vendors/revolution.addon.typewriter.min.js")
// require("./vendors/revolution.extension.actions.min.js")
// require("./vendors/revolution.extension.carousel.min.js")
// require("./vendors/revolution.extension.kenburn.min.js")
//
// require("./vendors/revolution.extension.layeranimation.min.js")
// require("./vendors/revolution.extension.migration.min.js")
// require("./vendors/revolution.extension.navigation.min.js")
// require("./vendors/revolution.extension.parallax.min.js")
// require("./vendors/revolution.extension.slideanims.min.js")
// require("./vendors/revolution.extension.video.min.js")
require("./vendors/dzsparallaxer.js")
require("./vendors/scroller.js")
require("./vendors/plugin.js")


require("./vendors/hs.core.js")
require("./vendors/hs.header.js")
require("./vendors/hs.hamburgers.js")
require("./vendors/hs.dropdown.js")
require("./vendors/hs.scrollbar.js")
require("./vendors/hs.countdown.js")
require("./vendors/hs.carousel.js")
require("./vendors/hs.go-to.js")
require("./vendors/hs.rating.js")
require("./vendors/hs.cubeportfolio.js")
require("./vendors/hs.slider.js")
require("./vendors/hs.step-form.js")
require("./vendors/hs.count-qty.js")
require("./vendors/hs.validation.js")
require("./vendors/typed.js")
//require("./analytics.js")
require("./custom.js")
require("./init.js")
require("./homes.js")
require("./cart.js")
require("./contact.js")
require("./shop.js")
require("./product.js")

var global_function = require("./custom.js")

$(document).on('turbolinks:load', function() {
  if (!(document.page.controller() === 'homes' && document.page.action() === 'index'))
    return;

  // initialization of carousel
  $.HSCore.components.HSCarousel.init('[class*="js-carousel"]');

  $('#carouselCus1').slick('setOption', 'responsive', [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }], true);

  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');  

  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // initialization of countdowns
  var countdowns = $.HSCore.components.HSCountdown.init('.js-countdown', {
    yearsElSelector: '.js-cd-years',
    monthElSelector: '.js-cd-month',
    daysElSelector: '.js-cd-days',
    hoursElSelector: '.js-cd-hours',
    minutesElSelector: '.js-cd-minutes',
    secondsElSelector: '.js-cd-seconds'
  });

  // initialization of quantity counter
  $.HSCore.components.HSCountQty.init('.js-quantity');
  let scrolling_text_default = [
    "0-6 ans",
    "sélectionnés avec amour"
  ];
  let scrolling_text_html = document.querySelector(".scrolling-text.hidden");
  if (scrolling_text_html) {
    let arr_scrolling_text = [];
    if (scrolling_text_html.textContent.includes(";")) {
      arr_scrolling_text = scrolling_text_html.textContent.split(";");
    } else {
      arr_scrolling_text.push(scrolling_text_html.textContent);
    }
    scrolling_text_default = arr_scrolling_text;
  }

  // initialization of text animation (typing)
  $(".u-text-animation.u-text-animation--typing").typed({
    strings: scrolling_text_default,
    typeSpeed: 60,
    loop: true,
    backDelay: 1500
  });

  //global_function.init_add_to_cart();
  $.HSCore.components.HSCubeportfolio.init('.cbp');
});


$(document).on('turbolinks:load', function () {
  if (!(document.page.controller() === 'homes' && (document.page.action() === 'concept' || document.page.action() === 'buying_back')))
    return;
  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');

  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of HSScrollBar component
  //$.HSCore.components.HSScrollBar.init($('.js-scrollbar'));

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // Tabs
  //$.HSCore.components.HSTabs.init('[role="tablist"]');

  $(window).on('resize', function () {
    setTimeout(function () {
      $.HSCore.components.HSTabs.init('[role="tablist"]');
    }, 200);
  });
});


$(document).on('turbolinks:load', function () {
  if (!(document.page.controller() === 'homes' && (document.page.action() === 'sales_point' || document.page.action() === 'buying_back')))
    return;

  let map;

  async function initMap() {
    const { Map } = await google.maps.importLibrary("maps");
    const position = { lat: 47.2798208, lng: -2.2136152 };
    map = new Map(document.getElementById("map"), {
      center: position,
      zoom: 15,
    });

    const marker = new google.maps.Marker({
      position,
      map,
      title: `Confetti Family boutique`
    });

    marker.setAnimation(google.maps.Animation.BOUNCE);
  }
  
  initMap();
  
  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');
  $.HSCore.components.HSCubeportfolio.init('.cbp');

  dzsas_init('.advancedscroller.auto-init', {init_each: true})
  $('.dzsparallaxer---window-height').each(function(){
      var _t = $(this);
      //return false;

      $(window).on('resize',handle_resize);
      handle_resize();

      function handle_resize(){
          var wh = window.innerHeight;

          _t.height(wh);
      }
  })
  dzsprx_init('.dzsparallaxer.auto-init', {init_each: true});
  dzsscr_init('.scroller-con.auto-init', {init_each: true});
  dzsscr_init('.scroller-con.auto-init-onload', {init_each: true});
});


$(document).on('turbolinks:load', function() {
  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');

  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of HSScrollBar component
  //$.HSCore.components.HSScrollBar.init($('.js-scrollbar'));

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // initialization of rating
  //$.HSCore.helpers.HSRating.init();

  // initialization of range slider
  //$.HSCore.components.HSSlider.init('#rangeSlider1');
});

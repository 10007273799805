!function(n, t, i) {
    "use strict";
    $(document).on('turbolinks:load', function() {
      function u(t, i) {
          this.element = t;
          this.zoomDefault = 14;
          this.prLoaded = [];
          this.settings = n.extend({}, f, i);
          this._defaults = f;
          this._name = r;
          this.ashx = "service2_3.ashx";
          this.svc = "services/parcelshop-picker.svc";
          this.w_name = "parcelshop-picker/v4_0";
          this.sw_url = i.Brand.trim().toLowerCase() == "bdtest" && i.Host ? i.Host : "widget.mondialrelay.com";
          this.widget = this.settings.Responsive ? this.settings.ShowResultsOnMap ? "widget_responsive.aspx" : "widget_responsive_list.aspx" : "widget.aspx";
          this.img_url = "www.mondialrelay.com";
          this.bounds = {};
          this.map = {};
          this.overlays = [];
          this.InfoWindow = null;
          this.container = {};
          this.callback = null;
          this.mapLoaded = {};
          this.containerId = null;
          this.params = null;
          this.mapId = null;
          this.protocol = "https://";
          this.version = i.version || c;
          this.libraryError = !1;
          var u = typeof L != "undefined"
            , s = typeof google != "undefined" && typeof google.maps != "undefined";
          this.UseOpenMap = !s || !this.settings.EnableGmap;
          this.UseOpenMap ? e() : o();
          this.settings.ShowResultsOnMap && this.UseOpenMap && !u ? (this.MR_loadjscssfile("https://unpkg.com/leaflet/dist/leaflet.css", "css"),
          this.MR_loadjscssfile("https://unpkg.com/leaflet/dist/leaflet.js", "js"),
          this.waitForLibThenInit()) : this.init()
      }
      var s = 20, h = 500, c = "4.0.6", r, f, e, o;
      t.MR_jQuery = n;
      n.fn.on || (n.fn.on = function(n, t) {
          n == "click" ? this.click(t) : this.live(n, t)
      }
      );
      console.log("MondialRelay widget jQuery " + n.fn.jquery);
      r = "MR_ParcelShopPicker";
      f = {
          Target: "",
          TargetDisplay: "",
          TargetDisplayInfoPR: "",
          Brand: "",
          Country: "FR",
          AllowedCountries: "",
          PostCode: "",
          EnableGeolocalisatedSearch: !0,
          ColLivMod: "24R",
          Weight: "",
          Service: "",
          NbResults: "7",
          SearchDelay: "",
          SearchFar: "75",
          CSS: "1",
          MapScrollWheel: !1,
          MapStreetView: !1,
          ShowResultsOnMap: !0,
          UseSSL: !0,
          ServiceUrl: "widget.mondialrelay.com",
          OnParcelShopSelected: null,
          OnNoResultReturned: null,
          OnSearchSuccess: null,
          DisplayMapInfo: !0,
          EnableGmap: !1,
          Host: null,
          AutoSelect: null
      };
      e = function() {
          n.extend(u.prototype, {
              MR_LoadMap: function(n) {
                  if (this.map[this.mapId] = L.map(this.mapId, {
                      keyboard: !0,
                      scrollWheelZoom: n.MapScrollWheel ? n.MapScrollWheel : !1,
                      doubleClickZoom: !1,
                      trackResize: !0,
                      minZoom: 9,
                      maxZoom: 20
                  }).setView([46.8, 1.69], 5),
                  L.control.scale({
                      metric: !0,
                      imperial: !1
                  }).addTo(this.map[this.mapId]),
                  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap<\/a> contributors',
                      scrollwheel: !1
                  }).addTo(this.map[this.mapId]),
                  this.bounds[this.mapId] = [],
                  this.overlays[this.mapId] = [],
                  this.mapLoaded[this.mapId] = !0,
                  this.settings.EnableLoadMore) {
                      var t = this;
                      this.map[this.mapId].on("dragend", function() {
                          t.MR_LoadFromCenter()
                      })
                  }
              },
              MR_ClearOverlays: function() {
                  for (var n = 0, t; t = this.overlays[this.mapId][n]; n++)
                      t.removeFrom(this.map[this.mapId]);
                  this.overlays[this.mapId] = [];
                  this.bounds[this.mapId] = []
              },
              MR_FocusOnMarker: function(n) {
                  for (var i = !1, t = 0; t < this.overlays[this.mapId].length; t++)
                      n == this.overlays[this.mapId][t].id && (this.MR_FocusOnMap(t),
                      i = !0);
                  return i
              },
              MR_AddMarker: function(n, t, i, r, u) {
                  var s = r.Available ? "pointsrelais_pin" : "pointsrelais_pin_grey", o = L.latLng(t, i), h = this.protocol + this.sw_url + "/" + this.w_name + "/css/imgs/" + s + ".png", c = new L.DivIcon({
                      className: "my-div-icon",
                      html: '<div class="marker-container"> <img class="marker-image" src="' + h + '"/><span class="marker-text">' + (u + 1) + "<\/span><\/div>",
                      iconSize: [45, 45],
                      iconAnchor: [22, 38],
                      popupAnchor: [0, -35]
                  }), f = new L.Marker(o,{
                      icon: c
                  }).addTo(this.map[this.mapId]), e, l;
                  if (f.id = r.Pays + "-" + r.ID,
                  e = this,
                  this.settings.DisplayMapInfo)
                      l = f.bindPopup("" + this.MR_BuildParcelShopDetails(r), {
                          closeButton: !0
                      });
                  else
                      f.on("click", function() {
                          e.map[e.mapId].setView(o)
                      });
                  f.on("click", function() {
                      var n = 17
                        , u = e.map[e.mapId]
                        , f = [t, i]
                        , o = u.project(f, n).subtract([0, 125])
                        , f = u.unproject(o, n);
                      u.setView(f, n);
                      e.MR_SelectParcelShop(r)
                  });
                  return this.overlays[this.mapId].push(f),
                  this.bounds[this.mapId].push(o),
                  f
              },
              MR_FocusOnMap: function(t) {
                  if (this.params.ShowResultsOnMap)
                      this.overlays[this.mapId][t].fire("click");
                  else if (this.settings.Responsive) {
                      var r = n(".InfoWindowFull:eq(" + t + ")", this.container).hasClass("Detail-Selected")
                        , i = n(".InfoWindowFull.Detail-Selected", this.container);
                      i.slideUp(function() {
                          i.removeClass("Detail-Selected")
                      });
                      r || n(".InfoWindowFull:eq(" + t + ")", this.container).addClass("Detail-Selected").slideDown();
                      n(".PR-List-Item:eq(" + t + ")").trigger("select")
                  } else
                      n("#" + this.mapId + " > div", this.container).hide(),
                      n("#" + this.mapId + " > div:nth-child(" + (t + 1) + ")", this.container).show().trigger("select")
              },
              MR_RebindMap: function() {
                  console.info("MR_RebindMap");
                  this.params == null ? console.info("MR_RebindMap() method has been called too early.") : this.params.ShowResultsOnMap && this.map[this.mapId].fitBounds(this.bounds[this.mapId])
              },
              MR_FitBounds: function() {
                  var n = this;
                  this.map[this.mapId].on("click", function() {
                      n.map[n.mapId].fitBounds(n.bounds[n.mapId])
                  });
                  setTimeout(function() {
                      n.map[n.mapId].invalidateSize();
                      n.container.find(".PR-Selected").length || n.map[n.mapId].fitBounds(n.bounds[n.mapId]);
                      setTimeout(function() {
                          n.zoomDefault = n.map[n.mapId].getZoom()
                      }, 100)
                  }, 500);
                  this.map[this.mapId].on("resize", function(t) {
                      n.container.find(".PR-Selected").length || t.target.fitBounds(n.bounds[n.mapId])
                  })
              },
              MR_IsMapLibLoaded: function() {
                  return typeof L == "undefined"
              }
          })
      }
      ;
      o = function() {
          n.extend(u.prototype, {
              MR_LoadMap: function(n) {
                  var t = {
                      zoom: 5,
                      center: new google.maps.LatLng(46.8,1.69),
                      mapTypeId: google.maps.MapTypeId.ROADMAP,
                      panControl: !1,
                      rotateControl: !0,
                      scaleControl: !0,
                      scrollwheel: n.MapScrollWheel ? n.MapScrollWheel : !1,
                      streetViewControl: n.MapStreetView ? n.MapStreetView : !1,
                      zoomControl: !0
                  };
                  this.map[this.mapId] = new google.maps.Map(i.getElementById(this.mapId),t);
                  this.bounds[this.mapId] = new google.maps.LatLngBounds;
                  this.overlays[this.mapId] = [];
                  this.mapLoaded[this.mapId] = !0
              },
              MR_ClearOverlays: function() {
                  for (var n = 0, t; t = this.overlays[this.mapId][n]; n++)
                      t.setMap(null);
                  this.overlays[this.mapId] = [];
                  this.bounds[this.mapId] = new google.maps.LatLngBounds
              },
              MR_FocusOnMarker: function(n) {
                  for (var i = !1, t = 0; t < this.overlays[this.mapId].length; t++)
                      n == this.overlays[this.mapId][t].id && (i = !0,
                      this.MR_FocusOnMap(t));
                  return i
              },
              MR_AddMarker: function(n, t, i, r, u) {
                  var o = new google.maps.LatLng(t,i)
                    , s = r.Available ? "gmaps_pin" : "gmaps_pin_grey"
                    , h = this.protocol + this.sw_url + "/" + this.w_name + "/css/imgs/" + s + ".png"
                    , e = new google.maps.Marker({
                      position: o,
                      map: this.map[this.mapId],
                      icon: new google.maps.MarkerImage(h),
                      label: {
                          text: "" + (u + 1),
                          color: "white",
                          fontFamily: "",
                          fontWeight: "bold",
                          fontSize: "14px"
                      }
                  })
                    , f = this;
                  return this.settings.DisplayMapInfo && google.maps.event.addListener(e, "click", function() {
                      f.InfoWindow != null && f.InfoWindow.close();
                      f.InfoWindow = new google.maps.InfoWindow({
                          content: f.MR_BuildParcelShopDetails(r)
                      });
                      f.InfoWindow.open(f.map[f.mapId], e)
                  }),
                  google.maps.event.addListener(e, "click", function() {
                      f.MR_SelectParcelShop(r);
                      f.map[f.mapId].setCenter(e.getPosition())
                  }),
                  this.overlays[this.mapId].push(e),
                  this.bounds[this.mapId].extend(o),
                  e
              },
              MR_FocusOnMap: function(t) {
                  this.params.ShowResultsOnMap ? google.maps.event.trigger(this.overlays[this.mapId][t], "click") : (n("#" + this.mapId + " > div", this.container).hide(),
                  n("#" + this.mapId + " > div:nth-child(" + (t + 1) + ")", this.container).show().trigger("select"))
              },
              MR_RebindMap: function() {
                  this.params == null ? console.info("MR_RebindMap() method has been called too early.") : this.params.ShowResultsOnMap && (google.maps.event.trigger(this.map, "resize"),
                  this.map[this.mapId].fitBounds(this.bounds[this.mapId]))
              },
              MR_FitBounds: function() {
                  var n = this;
                  this.map[this.mapId].fitBounds(this.bounds[this.mapId]);
                  this.map[this.mapId].addListener("click", function() {
                      n.map[n.mapId].fitBounds(n.bounds[n.mapId])
                  })
              },
              MR_IsMapLibLoaded: function() {
                  return typeof google == "undefined" || typeof google.maps == "undefined"
              }
          })
      }
      ;
      n.extend(u.prototype, {
          waitForLibThenInit: function(n) {
              if (n === s) {
                  console.error("Unable to load map lib");
                  this.libraryError = !0;
                  this.settings.ShowResultsOnMap = !1;
                  this.init();
                  return
              }
              if (this.MR_IsMapLibLoaded()) {
                  var t = this;
                  setTimeout(function() {
                      t.waitForLibThenInit(n ? ++n : 1)
                  }, h)
              } else {
                  this.init();
                  return
              }
          },
          bind: function() {
              n(this.element).bind("FocusOnMap", function(t, i) {
                  n.data(this, "plugin_" + r).MR_FocusOnMap(i)
              });
              n(this.element).bind("TabSelect", function(t, i) {
                  n.data(this, "plugin_" + r).MR_tabselect(i)
              });
              n(this.element).bind("ClearSelection", function() {
                  n.data(this, "plugin_" + r).MR_ClearSelection()
              });
              n(this.element).bind("CloseModalLocker", function() {
                  n.data(this, "plugin_" + r).MR_CloseModalLocker()
              });
              n(this.element).bind("MR_RebindMap", function() {
                  n.data(this, "plugin_" + r).MR_RebindMap()
              });
              n(this.element).bind("MR_SetParams", function(t, i) {
                  n.data(this, "plugin_" + r).MR_SetParams(i)
              });
              n(this.element).bind("MR_DoSearch", function(t, i, u) {
                  n.data(this, "plugin_" + r).MR_DoSearch(i, u)
              })
          },
          init: function() {
              this.MR_loadjscssfile("https://unpkg.com/leaflet/dist/leaflet.css", "css");
              this.MR_loadjscssfile("https://unpkg.com/leaflet/dist/leaflet.js", "js");
              this.protocol = this.settings.UseSSL ? "https://" : "http://";
              n(this.element).attr("id") || n(this.element).attr("id", "MRParcelShopPicker_" + Math.floor(Math.random() * 1e7 + 1));
              this.containerId = n(this.element).attr("id");
              this.settings.CSS != "0" && (this.MR_loadjscssfile(this.protocol + this.sw_url + "/" + this.w_name + "/css/style.min.css", "css", !0),
              this.settings.Responsive && (this.settings.ShowResultsOnMap ? this.MR_loadjscssfile(this.protocol + this.sw_url + "/" + this.w_name + "/css/responsive.min.css", "css", !0) : this.MR_loadjscssfile(this.protocol + this.sw_url + "/" + this.w_name + "/css/responsive-list.min.css", "css", !0)));
              this.container = this.element;
              this.loadhtml(n(this.element), this.protocol + this.sw_url + "/" + this.w_name + "/services/" + this.widget + "?allowedCountries=" + this.settings.AllowedCountries + "&Country=" + this.settings.Country + "&UseSSL=" + this.settings.UseSSL + "&Brand=" + n.trim(this.settings.Brand) + "&Container=" + this.containerId + "&CSS=" + this.settings.CSS + "&Responsive=" + this.settings.Responsive + "&NbResults=" + this.settings.NbResults + "&EnableGmap=" + this.settings.EnableGmap + "&ShowResultsOnMap=" + this.settings.ShowResultsOnMap, function() {
                  this.MR_Widget_Init(this.settings)
              });
              this.bind()
          },
          jsonpcall: function(t, i, r) {
              n.ajax({
                  context: this,
                  url: this.protocol + this.sw_url + "/" + t,
                  jsonp: "method",
                  dataType: "jsonp",
                  data: i,
                  success: r
              })
          },
          loadhtml: function(t, i, r) {
              var u = i.split(" ", 1)
                , f = u[0]
                , e = i.substring(i.indexOf(" ") + 1, i.length);
              this.container = t;
              this.callback = r;
              this.jsonpcall(this.ashx, {
                  downloadurl: f,
                  container: t.attr("id")
              }, function(t) {
                  n("#" + t.container).html(t.html);
                  n.isFunction(this.callback) && this.callback(t.container)
              })
          },
          MR_BuildItemPR: function(n, t) {
              var i = "";
              return i += "<div class='PR-List-Item " + (n.Available ? "" : "PR-Disabled") + "' onclick='MR_jQuery(\"#" + this.containerId + '").trigger("FocusOnMap",' + t + ")'><input type='hidden' class='PR-Id' value='" + n.Pays + "-" + n.ID + "'/><div class='PR-Name'>" + (t + 1) + " - " + n.Nom + "<\/div>",
              i + ("<div>" + n.Adresse1 + "<\/div><div>" + (n.Adresse2 || "") + "<\/div><div>" + n.CP + " - " + n.Ville + "<\/div><\/div>")
          },
          Manage_Response: function(t, i, r, u, f, e) {
              var s, k, l, v, o, p, w, c;
              if (t.PRList == null)
                  this.params.OnNoResultReturned && this.params.OnNoResultReturned();
              else {
                  for (o = 0; o < t.PRList.length; o++)
                      t.PRList[o].Letter = String.fromCharCode("A".charCodeAt(0) + o);
                  this.params.OnSearchSuccess && this.params.OnSearchSuccess(t)
              }
              if (s = -1,
              t.Error == null) {
                  k = this.container;
                  l = this.container.find(".MRW-Results");
                  l.slideDown("fast", function() {
                      l.show()
                  });
                  e || (this.prLoaded = []);
                  var y = this.prLoaded.length
                    , a = ""
                    , h = [];
                  for (o = 0; o < t.PRList.length; o++)
                      v = t.PRList[o].Pays + "-" + t.PRList[o].ID,
                      this.prLoaded.indexOf(v) < 0 && (a += this.MR_BuildItemPR(t.PRList[o], this.prLoaded.length),
                      h.push(t.PRList[o]),
                      this.prLoaded.push(v));
                  if (!e && this.params.AutoSelect && (s = this.prLoaded.indexOf(this.params.AutoSelect),
                  s == -1 || h[s] && h[s].Available != !1 || (s = -1)),
                  e ? this.container.find(".MRW-RList").append(a).show() : this.container.find(".MRW-RList").html(a).show(),
                  this.params.ShowResultsOnMap) {
                      for (this.mapLoaded[this.mapId] || this.MR_LoadMap(this.params),
                      e || this.MR_ClearOverlays(),
                      o = 0; o < h.length; o++)
                          this.MR_AddMarker(this.mapLoaded[this.mapId], h[o].Lat.replace(",", "."), h[o].Long.replace(",", "."), h[o], y, this.sw_url),
                          y++;
                      e || this.MR_FitBounds()
                  } else
                      for (n("#" + this.mapId, this.container).html(""),
                      c = this,
                      o = 0; o < t.PRList.length; o++)
                          this.settings.Responsive && !this.settings.ShowResultsOnMap ? (n(this.MR_BuildParcelShopFullDetails(t.PRList[o])).insertAfter(".PR-List-Item:eq(" + o + ")"),
                          n.data(n(".PR-List-Item:eq(" + o + ")")[0], "ParcelShop", t.PRList[o]),
                          n(".PR-List-Item:eq(" + o + ")").bind("select", function(t) {
                              c.MR_SelectParcelShop(n.data(t.target, "ParcelShop"))
                          })) : (n("#" + this.mapId, this.container).append(this.MR_BuildParcelShopDetails(t.PRList[o])),
                          n.data(n("#" + this.mapId + " > div:last-child")[0], "ParcelShop", t.PRList[o]),
                          n("#" + this.mapId + " > div:last-child").bind("select", function(t) {
                              c.MR_SelectParcelShop(n.data(t.target, "ParcelShop"))
                          }),
                          n("#" + this.mapId + " > div", this.container).hide());
                  s != -1 ? (p = this.container.find('.PR-Id[value="' + this.params.AutoSelect + '"]'),
                  w = p.parent().offset().top - 150,
                  n(".MRW-RList").scrollTop(w),
                  this.MR_FocusOnMap(s)) : this.settings.Responsive && !e && this.container.find(".MRW-RList").removeClass("Hide").animate({
                      scrollTop: 0
                  }, "fast")
              } else
                  e || (this.container.find(".MRW-Results").hide(),
                  this.container.find(".MRW-Errors").html(t.Error).slideDown("slow"));
              this.container.find(".progressBar").hide();
              this.container.find(".PR-List-Item").on("mouseover", function() {
                  n(this).hasClass("PR-Disabled") || n(this).addClass("PR-hover")
              });
              this.container.find(".PR-List-Item").on("mouseout", function() {
                  n(this).removeClass("PR-hover")
              });
              if (this.settings.Responsive && this.settings.ShowResultsOnMap && this.settings.CSS == !0) {
                  c = this;
                  function b() {
                      var t = n(".MRW-Results")
                        , i = t.height()
                        , r = t.width();
                      n(".MRW-Map").height(i);
                      e || c.MR_FitBounds();
                      r && i || setTimeout(b, 1e3)
                  }
                  setTimeout(b, 1e3)
              }
          },
          MR_LoadFromCenter: function() {
              var n;
              if (!(this.prLoaded.length > 50) && (n = this.map[this.mapId].getZoom(),
              !(n > this.zoomDefault))) {
                  var t = this.map[this.mapId].getCenter()
                    , w = this.container
                    , i = this.settings.Target
                    , r = this.settings.TargetDisplay
                    , u = this.settings.TargetDisplayInfoPR
                    , f = this.settings.Brand
                    , e = this.container.find("input.Arg1")[0].value
                    , o = this.params.ColLivMod
                    , s = this.settings.Weight
                    , h = this.settings.SearchDelay
                    , c = this.settings.SearchFar
                    , l = this.settings.VacationBefore || ""
                    , a = this.settings.VacationAfter || ""
                    , v = this.settings.Service
                    , y = t.lat.toFixed(7)
                    , p = t.lng.toFixed(7);
                  this.jsonpcall(this.w_name + "/" + this.svc + "/SearchPR", {
                      Brand: f,
                      Country: e,
                      PostCode: "",
                      ColLivMod: o,
                      Weight: s,
                      NbResults: 10,
                      SearchDelay: h,
                      SearchFar: c,
                      ClientContainerId: this.containerId,
                      VacationBefore: l,
                      VacationAfter: a,
                      Service: v,
                      Latitude: y,
                      Longitude: p,
                      LoadMore: !0
                  }, function(n) {
                      this.Manage_Response(n, this.container, i, r, u, !0)
                  })
              }
          },
          MR_Widget_Call: function(n, t, i, r, u) {
              this.container.find(".MRW-Errors").hide();
              this.container.find(".progressBar").show();
              this.container.find(".MRW-Errors").html("");
              var e = this.settings.Brand
                , o = this.container.find("input.Arg1")[0].value
                , w = this.container.find("input.Arg2")[0].value
                , s = this.params.ColLivMod
                , h = this.settings.Weight
                , c = this.settings.NbResults
                , l = this.settings.SearchDelay
                , a = this.settings.SearchFar
                , v = this.settings.VacationBefore || ""
                , y = this.settings.VacationAfter || ""
                , p = this.settings.Service
                , f = this;
              u ? navigator.geolocation.getCurrentPosition(function(n) {
                  var u = n.coords.latitude.toFixed(7)
                    , w = n.coords.longitude.toFixed(7);
                  f.jsonpcall(f.w_name + "/" + f.svc + "/SearchPR", {
                      Brand: e,
                      Country: o,
                      PostCode: "",
                      ColLivMod: s,
                      Weight: h,
                      NbResults: c,
                      SearchDelay: l,
                      SearchFar: a,
                      ClientContainerId: f.containerId,
                      VacationBefore: v,
                      VacationAfter: y,
                      Service: p,
                      Latitude: u,
                      Longitude: w
                  }, function(n) {
                      f.Manage_Response(n, f.container, t, i, r)
                  })
              }, function(n) {
                  alert("Erreur à l'obtention des données de géolocalisation : [" + n.code + "] " + n.message)
              }, {
                  maximumAge: 12e4
              }) : this.jsonpcall(this.w_name + "/" + this.svc + "/SearchPR", {
                  Brand: e,
                  Country: o,
                  PostCode: w,
                  ColLivMod: s,
                  Weight: h,
                  NbResults: c,
                  SearchDelay: l,
                  SearchFar: a,
                  ClientContainerId: this.containerId,
                  VacationBefore: v,
                  VacationAfter: y,
                  Service: p,
                  Latitude: "",
                  Longitude: ""
              }, function(n) {
                  this.Manage_Response(n, this.container, t, i, r)
              })
          },
          MR_ClearSelection: function() {
              if (this.container.find(".PR-Selected").removeClass("PR-Selected"),
              n(this.settings.TargetDisplayInfoPR).html(""),
              n(this.settings.Target).val("").trigger("change"),
              this.settings.TargetDisplay && n(this.settings.TargetDisplay).val(""),
              this.settings.OnParcelShopSelected)
                  this.settings.OnParcelShopSelected({
                      ID: "",
                      Nom: "",
                      Adresse1: "",
                      Adresse2: "",
                      CP: "",
                      Ville: "",
                      Pays: ""
                  });
              this.map[this.mapId].closePopup();
              this.MR_RebindMap();
              n("#LockerModal").hide()
          },
          MR_CloseModalLocker: function() {
              n("#LockerModal").hide()
          },
          MR_SelectParcelShop: function(t) {
              t.Available && (n(this.settings.Target).val(t.Pays + "-" + t.ID).trigger("change"),
              this.settings.TargetDisplay && n(this.settings.TargetDisplay).val(t.Pays + "-" + t.ID),
              this.settings.TargetDisplayInfoPR && n(this.settings.TargetDisplayInfoPR).html((t.Nom == null ? "" : t.Nom + "<br/>") + (t.Adresse1 == null ? "" : t.Adresse1 + "<br/>") + (t.Adresse2 == null ? "" : t.Adresse2 + "<br/>") + t.CP + " " + t.Ville + " " + t.Pays),
              this.container.find(".PR-Selected").removeClass("PR-Selected"),
              this.container.find('.PR-Id[Value="' + t.Pays + "-" + t.ID + '"]').parent().addClass("PR-Selected"),
              t.Nature == "K" && n("#LockerModal").show(),
              this.settings.OnParcelShopSelected && this.settings.OnParcelShopSelected(t))
          },
          MR_BuildParcelShopDetails: function(n) {
              var t = n.ID + "_01"
                , i = n.ID + "_02";
              return '<div class="InfoWindow"><div class="PR-Name">' + n.Nom + "<\/div>" + n.Warning + '<div class="Tabs-Btns"><span class="Tabs-Btn Tabs-Btn-Selected" id="btn_' + t + '" onclick="MR_jQuery(\'#' + this.containerId + "').trigger('TabSelect','" + t + "');\">" + MondialRelayLanguage.Horaires + '<\/span><span class="Tabs-Btn" id="btn_' + i + '" onclick="MR_jQuery(\'#' + this.containerId + "').trigger('TabSelect','" + i + "');\">" + MondialRelayLanguage.Photo + '<\/span><\/div><div class="Tabs-Tabs"><div class="Tabs-Tab Tabs-Tab-Selected" id="tab_' + t + '">' + n.HoursHtmlTable + '<\/div><div class="Tabs-Tab" id="tab_' + i + '"><img src="' + this.protocol + this.img_url + "/ww2/img/dynamique/pr.aspx?id=" + n.Pays + this.MR_pad_left(n.ID, "0", 6) + '" width="182" height="112" alt="No picture"/><\/div><\/div><\/div>'
          },
          MR_BuildParcelShopFullDetails: function(n) {
              return '<div class="InfoWindow InfoWindowFull" style="display:none"><div class="InfoWindowFull-Hours">' + n.HoursHtmlTable + '<\/div><div class="InfoWindowFull-Image"><img src="' + this.protocol + this.img_url + "/ww2/img/dynamique/pr.aspx?id=" + n.Pays + this.MR_pad_left(n.ID, "0", 6) + '" alt="No picture"/><\/div><\/div>'
          },
          MR_loadjscssfile: function(n, t, r) {
              var u, e, o, f;
              if (r && this.version && (n += "?v=" + this.version),
              t == "js") {
                  if (e = i.getElementsByTagName("head")[0].getElementsByTagName("script"),
                  e && e.length)
                      for (f = 0; f < e.length; f++)
                          if (e[f].src == n)
                              return;
                  u = i.createElement("script");
                  u.setAttribute("type", "text/javascript");
                  u.setAttribute("src", n)
              } else if (t == "css") {
                  if (o = i.getElementsByTagName("head")[0].getElementsByTagName("link"),
                  o && o.length)
                      for (f = 0; f < o.length; f++)
                          if (o[f].href == n)
                              return;
                  u = i.createElement("link");
                  u.setAttribute("rel", "stylesheet");
                  u.setAttribute("type", "text/css");
                  u.setAttribute("href", n)
              }
              typeof u != "undefined" && i.getElementsByTagName("head")[0].appendChild(u)
          },
          MR_pad_left: function(n, t, i) {
              var u, r;
              if (!n || !t || n.length >= i)
                  return n;
              for (u = (i - n.length) / t.length,
              r = 0; r < u; r++)
                  n = t + n;
              return n
          },
          MR_Widget_Init: function(t) {
              var i = this, e, r, u, f;
              this.params = t;
              e = this.container.find("input.iArg0");
              r = n("<div>");
              this.mapId = this.container.find(".MRW-Map").attr("id");
              this.bounds[this.mapId] = [];
              this.overlays[this.mapId] = [];
              this.overlays[this.mapId] = [];
              r.addClass("PR-AutoCplCity");
              r.css("width", e.width());
              this.container.find(".MRW-Search").append(r);
              this.container.find("input.Arg2").on("keydown", function() {
                  i.container.find(".PR-AutoCplCity").html("").slideUp("fast")
              });
              this.container.find("input.iArg0").on("keydown", function(t) {
                  var r = t.keyCode || t.which
                    , u = i.container.find("input.iArg0")[0].value
                    , e = i.container.find("input.Arg1")[0].value
                    , f = String.fromCharCode(r);
                  if (r == 40)
                      i.container.find(".PR-AutoCplCity .AutoCpl-Hover").length === 0 ? i.container.find(".PR-AutoCplCity div:first-child").addClass("AutoCpl-Hover") : i.container.find(".AutoCpl-Hover").next().length > 0 && i.container.find(".AutoCpl-Hover").removeClass("AutoCpl-Hover").next().addClass("AutoCpl-Hover");
                  else if (r == 38)
                      i.container.find(".PR-AutoCplCity .AutoCpl-Hover").length === 0 ? i.container.find(".PR-AutoCplCity div:last-child").addClass("AutoCpl-Hover") : i.container.find(".AutoCpl-Hover").prev().length > 0 && i.container.find(".AutoCpl-Hover").removeClass("AutoCpl-Hover").prev().addClass("AutoCpl-Hover");
                  else {
                      if ((r == 13 || r == 9) && i.container.find(".AutoCpl-Hover").length > 0) {
                          t.preventDefault();
                          i.container.find("input.Arg2")[0].value = i.container.find(".AutoCpl-Hover").attr("title");
                          i.container.find("input.iArg0")[0].value = i.container.find(".AutoCpl-Hover").attr("name");
                          i.container.find(".PR-AutoCplCity").html("").slideUp("fast");
                          return
                      }
                      /[a-zA-Z0-9\-_ ]/.test(f) ? (u = u + f,
                      u.length > 3 && (i.container.find(".PR-AutoCplCity").css("top", i.offsetTop + 20 + "px"),
                      i.container.find(".PR-AutoCplCity").css("left", i.offsetLeft + "px"),
                      i.jsonpcall(i.w_name + "/" + i.svc + "/AutoCPLCity", {
                          PostCode: "",
                          Country: e,
                          City: u
                      }, function(t) {
                          var r, u;
                          for (i.container.find(".PR-AutoCplCity").html(""),
                          r = 0; r < t.Value.length; r++) {
                              u = n("<div>");
                              u.attr("title", t.Value[r].PostCode);
                              u.attr("name", t.Value[r].Name);
                              u.addClass("PR-City");
                              u.html(t.Value[r].Name + " (" + t.Value[r].PostCode + ")");
                              i.container.find(".PR-AutoCplCity").append(u);
                              u.on("click", function() {
                                  i.container.find("input.Arg2")[0].value = n(this).attr("title");
                                  i.container.find("input.iArg0")[0].value = n(this).attr("name");
                                  i.container.find(".PR-AutoCplCity").html("").slideUp("fast")
                              })
                          }
                          i.container.find(".PR-AutoCplCity").slideDown("fast")
                      }))) : i.container.find(".PR-AutoCplCity").html("").slideUp("fast")
                  }
              });
              this.container.find("input.iArg0").on("blur", function() {
                  i.container.find(".AutoCpl-Hover").length && (i.container.find("input.Arg2")[0].value = i.container.find(".AutoCpl-Hover").attr("title"),
                  i.container.find("input.iArg0")[0].value = i.container.find(".AutoCpl-Hover").attr("name"))
              });
              u = this.container.find(".MRW-BtGo");
              u.on("click", function() {
                  var t = n(this);
                  return i.MR_Widget_Call(i.container, i.settings.Target, i.settings.TargetDisplay, i.settings.TargetDisplayInfoPR, !1),
                  !1
              });
              f = function(n) {
                  n.preventDefault();
                  n.keyCode === 13 && u.click()
              }
              ;
              this.container.find("input.Arg2").on("keyup", f);
              this.container.find("input.iArg0").on("keyup", f);
              if ("geolocation"in navigator || (this.params.EnableGeolocalisatedSearch = !1),
              this.params.EnableGeolocalisatedSearch)
                  this.container.find(".MRW-BtGeoGo").on("click", function() {
                      var t = n(this);
                      return i.MR_Widget_Call(i.container, i.settings.Target, i.settings.TargetDisplay, i.settings.TargetDisplayInfoPR, !0),
                      !1
                  });
              else
                  this.container.find(".MRW-BtGeoGo").hide();
              this.container.find(".MRW-flag").on("click", function() {
                  var t = n(this);
                  i.container.find(".MRW-fl-Select").slideToggle(200).css("top", this.offsetTop + this.height + 2 + "px").css("left", this.offsetLeft - 3 + "px")
              });
              this.container.find(".MRW-fl-Item").on("click", function() {
                  var t = n(this);
                  i.container.find(".MRW-fl-Select").slideUp("fast");
                  i.container.find(".MRW-flag").attr("src", t.find("img").attr("src"));
                  i.container.find("input.Arg1")[0].value = t.find("img").attr("alt")
              });
              this.container.find("input.Arg0")[0].value = t.Brand;
              this.container.find("input.Arg1")[0].value = t.Country;
              this.container.find("input.Arg2")[0].value = t.PostCode;
              this.container.find("input.Arg3")[0].value = t.ColLivMod;
              this.container.find("input.Arg4")[0].value = t.Weight;
              this.container.find("input.Arg5")[0].value = t.NbResults;
              this.container.find("input.Arg6")[0].value = t.SearchDelay;
              this.container.find("input.Arg7")[0].value = t.SearchFar;
              this.container.find("input.Arg10")[0].value = t.Service;
              t.PostCode != "" && this.MR_Widget_Call(this.container, t.Target, t.TargetDisplay, t.TargetDisplayInfoPR, !1)
          },
          MR_Destroy: function(t) {
              this.container = n(t);
              this.container.find("input.Arg2").unbind("keydown");
              this.container.find("input.Arg2").unbind("keyup");
              this.container.find("input.iArg0").unbind("keydown");
              this.mapLoaded[this.mapId] = !1
          },
          MR_SetParams: function(t) {
              this.params == null ? console.info("MR_SetParams() method has been called too early.") : (this.settings = n.extend(this.params, t),
              this.params = n.extend(this.params, t))
          },
          MR_DoSearch: function(n, t) {
              this.container.find("input.Arg2").val(n);
              this.container.find("input.Arg1").val(t);
              this.MR_Widget_Call(this.container, this.settings.Target, this.settings.TargetDisplay, this.settings.TargetDisplayInfoPR, !1)
          },
          MR_tabselect: function(t) {
              var i = t.split("_")
                , r = i[0] + "_" + (i[1] === "01" ? "02" : "01");
              n("#btn_" + r, this.container).removeClass("Tabs-Btn-Selected");
              n("#btn_" + t, this.container).addClass("Tabs-Btn-Selected");
              n("#tab_" + r, this.container).removeClass("Tabs-Tab-Selected");
              n("#tab_" + t, this.container).addClass("Tabs-Tab-Selected")
          }
      });
      n.fn[r] = function(t) {
          return this.each(function() {
              n.data(this, "plugin_" + r) || n.data(this, "plugin_" + r, new u(this,t))
          })
      }
    });
}(window.MR_ENV && window.MR_ENV.$ ? window.MR_ENV.$ : jQuery, window, document);
